import { getNFTImageURL } from './getImageUrl'
import { useSingleShip } from './deepspace'
export type TshipType = 'inventory' | 'shipsoutpost' | 'my-listing' | 'in-game-asset' | 'staking' | 'unstaking' | 'ship-link'
export const useFetchImageData = (shipId: number) => {
  const { data, isLoading } = useSingleShip(shipId)

  let shipType: TshipType
  if (data && data.length) {
    const imageUrl = getNFTImageURL(data[0]['ship'].shipType, data[0]['ship'].textureType, data[0]['ship'].textureNum)

    if (data[0]['listing']) {
      shipType = 'shipsoutpost'
      return { data, isLoading, shipType, imageUrl }
    } else {
      shipType = 'inventory'
      return { data, isLoading, shipType, imageUrl }
    }
  }
}