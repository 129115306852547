import { useFetchImageData } from '@/hooks/fetchImageData'
import styled from 'styled-components'
import ShipCard from '../../components/ShipCard'
import { useRouter } from 'next/router'
const PageStyle = styled.div`
  width 100%;
  min-height: 100vh;
`
export default function Page() {
  const route = useRouter()
  const image = useFetchImageData(parseInt(route.query.shipId as string))

  return (
    <PageStyle className="relative z-10 w-full h-full">
      {(image?.isLoading ?? true)? (
        <div className="w-full h-full text-center text-white pt-80">
          <div className="loading" />
          Loading...
        </div>
      ) : (
        <ShipCard
          cardType={image?.shipType}
          nftData={image?.shipType === 'inventory' ? image?.data[0].ship : image?.data[0]?.listing.token}
          price={image?.data[0]?.listing?.price ?? '0'}
          nftFullData={image?.shipType === 'inventory' ? image?.data[0]['ship'] : image?.data[0]['listing']}
          imgURL={image?.imageUrl}
        />
      ) }
      <div id="modal-root"></div>
    </PageStyle>
  )
}
